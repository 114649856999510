@import 'assets/fonts/fonts.scss';

body {
	--dark-blue-header: #2a406f;
	--light-blue: #34518f;

	--blue: #345190;
	--blue-title: #39548c;
	--blue-medium: #496fc0;
	--blue-light-normal: #6293fc;
	--background-blue: #d1d9e5b5;
	--blue-light: #56ccf2;
	--blue-extra-light: #d2e1ff;
	--white: #ffffff;
	--black: #353535;
	--black-light: #595555;
	--blue-border: #2a406e;

	--light-red: #fc473d3d;
	--red: #fc473d;
	--red-title: #fc473d;

	--orange: #f2994a;
	--yellow: #f2c94c;
	--yellow-alert: #ffda56;
	--yellow-warn: #deae34;

	--green: #1ec589;
	--green-normal: #34a853;
	--green-medium: #6fcf97;

	--natural: #f5f5f5;

	--light-green: #77cf47;

	--green-success: #49c96d17;
	--dark-green: #20a144;

	--red-danger: #ff965d1f;
	--red-title: #fc473d;

	--gray: #4f4f4f;
	--gray-label: #9a9a9a;
	--gray-normal: #828282;
	--gray-light: #d8d8d8;
	--gray-check: #aaaaaa;
	--gray-extra-light: #e8e8e8;
	--gray-dark: #595555;
	--gray-border: #cccccc;
	--gray-text: #5f5f5f;
	--gray-very-light: #d9d9d938;

	--heightDefaultConsult: 124px;
	--heightDefaultExams: 195px;

	--underweight: rgba(85, 179, 247, 0.11);
	--normal: rgba(30, 197, 137, 0.11);
	--overweight: rgba(252, 139, 63, 0.21);
	--obese: rgba(252, 139, 63, 0.31);
	--extreme-obese: rgba(220, 53, 69, 0.11);

	--night-rider: #2d2b2b;
	--whisper: #ececec;
	--light-sky-blue: #86c9fd;
	--very-light-sky-blue: #86c9fd47;
	--royal-blue: #4285f4;
	--dew: #e6f4e9;
	--white-smoke: #f1f1f1;
	--zambezi: #5c5c5c;

	--disabled-label: #bcbcbc;

	--gray-title: #3f434a;
	--gray-title-light: #595f69;

	--light-gray: #8a9099;
	--light-line-gray: #e4e6ef;
	--sky-blue: #e1ecff;
	--sky-blue-background: #f0f6ff;

	font-family: 'Montserrat', sans-serif;
}

.show-pass {
	position: absolute;
	right: 0;
	top: 14px;
	border: 0;
	background: transparent;

	&:after {
		content: '';
		position: absolute;
		left: 15px;
		top: 1px;
		width: 3px;
		height: 26px;
		background: #828282;
		transform: rotate(45deg);
	}

	&.active {
		&:after {
			content: none;
		}
	}

	figure {
		margin: 0;
	}
}

h3,
ul {
	margin: 0;
}

.cursor-pointer {
	cursor: pointer !important;
}

.is-invalid ~ .invalid-feedback {
	position: absolute;
	width: 100%;
	max-width: 280px;
	display: inline-block;
}

.invalid-feedback {
	font-size: 0.725rem;
}

.version-app {
	position: fixed;
	left: 5px;

	&-bottom {
		bottom: 0;
	}

	&-top {
		top: 0;
	}
}

.hover-scroll-overlay-y {
	overflow-y: scroll !important;
	height: calc(100vh - 170px);
}

.page-item.active .page-link {
	color: #d3e2e2;
}

figure,
p,
h4 {
	margin: 0;
}

.modal-dialog {
	max-width: 900px;
	margin: 1.75rem auto;
	height: calc(100% - 46px);
	display: flex;
	align-items: center;
}

.text-right {
	text-align: right;
}

.symbol-circle {
	figure {
		border-radius: 50%;
		overflow: hidden;
		margin: 0;
	}
}

.ng-wizard {
	&-main {
		.step-content {
			padding: 0 5px 0 15px;
		}
	}

	&-theme-arrows {
		& > ul.step-anchor {
			background: transparent;

			& > li {
				a {
					background: var(--gray-extra-light);
					color: var(--black);

					&:after {
						border-left: 30px solid var(--gray-extra-light) !important;
					}

					&:hover {
						background: var(--gray-extra-light);
					}
				}

				&.active > a {
					background: var(--blue-medium) !important;

					&:after {
						border-left: 30px solid var(--blue-medium) !important;
					}
				}

				&.done > a {
					background: var(--blue-light-normal) !important;

					&:after {
						border-left: 30px solid var(--blue-light-normal) !important;
					}
				}

				@media (max-width: 560px) {
					display: block;
					width: 100%;
				}

				@media (max-width: 993px) {
					a {
						padding-right: 10px;
					}
				}
			}
		}
	}
}

.effect-hover {
	transition: all 0.4s ease !important;

	&:hover {
		opacity: 0.4;
	}
}

.full-calendar {
	min-height: 672px;

	@media (max-width: 770px) {
		min-height: 732px !important;
	}

	.fc-prev-button,
	.fc-next-button,
	.fc-today-button {
		background-color: transparent !important;
	}

	.fc-view-harness {
		.fc-dayGridMonth-view {
			overflow: auto;
			width: 100%;

			// .fc-scrollgrid {
			// 	min-width: 1130px;
			// }
		}

		th {
			// width: 160px;
			width: auto;
		}

		td {
			height: 92px;
			// width: 160px;
			width: auto;
			cursor: pointer;

			.fc-daygrid-day-frame {
				// min-width: 160px !important;
				width: auto;
				min-height: 92px !important;
			}

			&.fc-day-future {
				cursor: not-allowed;

				i {
					display: none;
				}
			}

			&.fc-day-other {
				.fc-daygrid-event {
					opacity: 0.3 !important;
				}
			}
		}
	}

	.fc-today-button {
		font-style: normal !important;
		font-weight: 500 !important;
		font-size: 18px !important;
		line-height: 22px !important;
		color: var(--black) !important;
	}

	.fc-dayGridWeek-button,
	.fc-dayGridMonth-button,
	.fc-dayGridDay-button {
		background-color: transparent !important;
		font-style: normal !important;
		font-weight: 500 !important;
		font-size: 13px !important;
		line-height: 16px !important;
		text-transform: lowercase;

		&[aria-pressed='true'] {
			background-color: var(--white-smoke) !important;
		}
	}

	.fc-daygrid-day-number {
		position: inherit !important;
		height: 22px;
	}

	.fc-daygrid-day-top {
		height: 15px;
		.custom-calendar-day {
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 15px;
			color: var(--zambezi);
			margin: 0px;

			text-align: end;
		}

		.custom-calendar-hrs {
			position: absolute;
			top: 58%;
			left: 50%;
			transform: translate(-50%, -50%);

			width: 100%;


			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
            gap: 16px;

			.box-hrs {
                width: 100%;
				display: flex;
                flex-direction: column;
				align-items: center;
				justify-content: start;
                gap: 4px;
                padding: 10px 0 0 8px;


				.hrs-normal {
					font-style: normal;
					font-size: 15px;
					line-height: 15px;
					color: var(--royal-blue);
					margin: 0px;
				}

				.hrs-overtime {
					font-style: normal;
					font-size: 15px;
					line-height: 15px;
					color: var(--red);
					margin: 0px;
				}
			}

			.box-totalhrs {
				width: 100%;
				display: flex;
				justify-content: end;
				align-items: center;
				gap: 4px;
				padding: 0 8px 0 0;

				.hrs-totais {
					font-style: normal;
					font-size: 14px;
					line-height: 15px;
					color: var(--black);
					margin: 0px;
				}
			}
		}
	}

	.fc-day-today {
		background-color: rgb(252 71 61 / 8%) !important;
	}

	.fc-daygrid-event {
		background-color: transparent;
		border: 1px solid var(--royal-blue);
		border-radius: 4px;
		margin-top: 9px !important;
		overflow: hidden;

		h4 {
			margin: 0;
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-size: 12px;
			line-height: 17px;
			color: var(--night-rider);
			padding-left: 7px;
			font-weight: 600;
			cursor: pointer;
		}
	}

	.fc-daygrid-day-frame {
		overflow: hidden !important;
	}

	.fc-more-popover {
		border-radius: 17px !important;

		.fc-more-popover-misc,
		.fc-popover-header {
			display: none !important;
		}

		.fc-popover-body {
			background-color: var(--white) !important;
			box-shadow: 0px 4px 4px rgb(0 0 0 / 25%) !important;
			border-radius: 17px !important;
			padding: 15px !important;
		}

		.fc-daygrid-event-harness {
			right: 0 !important;

			.fc-daygrid-event {
				background-color: transparent;
				border: none;
				margin-bottom: 5px;
				transform: all 0.3 ease;

				&:hover {
					outline: 1px solid var(--royal-blue);
				}

				h4 {
					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 16px;
					padding: 3px;
				}
			}
		}
	}

	.fc-daygrid-body,
	.fc-col-header {
		width: 100% !important;
		height: 100% !important;

		table {
			width: 100% !important;
			height: 100% !important;
		}
	}
}

td {
	&.fc-day-sat,
	&.fc-day-sun {
		background-color: var(--whisper) !important;
	}
}

.fc-popover {
	z-index: 1 !important;
}

.select2-container--open {
	z-index: 9999 !important;
}

.select2-container--disabled {
	opacity: 0.4 !important;
}

.select2-container--default {
	border: none !important;
}

.select2 {
	width: 100% !important;

	.select2-selection {
		background-color: var(--white) !important;
		border-radius: 8px !important;
		min-height: 45px !important;
		display: flex;
		align-items: center;
		border: none;

		.select2-selection__placeholder {
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			color: var(--night-rider) !important;
		}

		.select2-selection__rendered {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			margin-top: 6px;
			color: var(--night-ride) !important;
		}

		.select2-search__field {
			margin-top: 10px !important;
			margin-left: 12px !important;
			font-size: 1.1rem !important;
			font-weight: 500 !important;
			line-height: 1.5 !important;
			color: #181c32 !important;
			font-family: 'Montserrat' !important;
		}

		.select2-selection__arrow {
			top: 10px !important;
			right: 5px !important;
		}

		.select2-selection__choice {
			padding: 4px !important;
			padding-left: 20px !important;
			background-color: var(--royal-blue) !important;
			color: var(--white);
			border: none !important;

			button {
				border-right: none !important;
				color: var(--white);
				margin-top: 4px;
				padding: 0 6px;

				&:hover {
					opacity: 0.4;
					background-color: transparent;
				}
			}
		}
	}
}

.border-20 {
	border-radius: 20px;
}

.wrapper-check {
	width: 14px;
	height: 14px;
	display: flex;
	border-radius: 2px;
	border: 1px solid var(--black);
	padding: 0;

	@media (max-width: 480px) {
		width: 25px;
	}

	input {
		appearance: none;
		cursor: pointer;

		&:checked {
			width: 10px;
			height: 10px;
			margin: auto;
			background-color: var(--black);
			border-radius: 2px;
		}
	}
}

input[type='checkbox' i]:focus-visible {
	outline: 0px !important;
}

.btn-shadow {
	color: var(--white);
	background-color: var(--light-sky-blue);
	border-radius: 14px;
	border: none;
	box-shadow: 0px 4px 1px 0px #6ea5cf;
	width: 48px;
	height: 35px;
	transition: all 0.3s ease;
	font-size: 23px;

	&:hover {
		box-shadow: inset 0px 3px 1px 0px #6ea5cf;
		opacity: 0.8;
	}
}

.btn-bg-white {
	background-color: var(--white);
	padding: 10px;
	border: none;
	border-radius: 4px;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.6;
	}
}

.title-content {
	font-style: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
}

.select-dashboard {
	.select2 {
		max-width: 150px !important;

		.select2-selection {
			padding: 6px 30px 6px 10px !important;
			height: 100%;
			border: none;

			.select2-selection__rendered {
				color: var(--gray-title) !important;
				font-style: normal !important;
				font-weight: 400 !important;
				font-size: 14px !important;
			}

			.select2-selection__arrow {
				top: 6px !important;
			}
		}
	}
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
	border: 1px solid #e4e6ef !important;
}

.modal-confirm > .modal-dialog {
	max-width: 400px;
	width: 100%;

	.modal-content {
		padding: 20px;
		border-radius: 30px !important;
		margin: 0 auto !important;

		@media (max-width: 993px) {
			padding: 15px !important;
			max-width: 320px;
		}
	}
}

.modal-lg-calendar > .modal-dialog {
	max-width: 1400px;
	width: 100%;

	.modal-content {
		max-height: 95%;
		overflow: auto;
		padding: 20px;
		border-radius: 30px !important;
		margin: 0 auto !important;

		@media (max-width: 993px) {
			padding: 15px !important;
			max-width: 320px;
		}
	}
}

.btn-default {
	background-color: var(--royal-blue);
	border: none;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
		0px 2px 3px rgba(0, 0, 0, 0.168);
	border-radius: 10px;
	padding: 5px 15px;
	transition: all 0.3s ease;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 28px;
	color: var(--white);

	&:hover {
		opacity: 0.6;
		color: var(--white);
	}
}

.modal-timesheet > .modal-dialog {
	max-width: 1000px;
	width: 100%;

	.modal-content {
		padding: 20px;
		border-radius: 58px;

		@media (max-width: 993px) {
			padding: 10px;
			max-height: 90%;
			overflow: auto;
			margin: 0 10%;
		}

		@media (max-width: 993px) {
			padding: 10px;
			max-height: 90%;
			overflow: auto;
			margin: 0 3%;
		}

		@media (max-width: 993px) {
			border-radius: 0;
			margin: 0;
			max-height: 100%;
		}
	}

	@media (max-width: 993px) {
		margin: 0;
	}
}

.modal-service > .modal-dialog {
	width: 100%;

	.modal-content {
		padding: 20px;
		max-height: 95%;
		overflow: auto;
		border-radius: 40px;
		margin: 0 10px;

		.wrapper-buttons {
			margin-top: 30px;

			.btn-save {
				max-width: 180px;
				width: 100%;
				height: 49px;
			}

			.btn-cancel {
				height: 49px;
				max-width: 180px;
				width: 100%;
				box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
					0px 2px 3px rgba(0, 0, 0, 0.168);
				border-radius: 10px;
				font-weight: 600;
			}
		}
	}
}

.wrapper-search {
	position: relative;

	.input-search {
		padding: 10px 50px;
		border-radius: 20px;
		border: 1px solid var(--gray-extra-light);
		transition: all 0.1s ease;

		&:focus-visible {
			outline: none;
		}

		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
		}
	}

	.search {
		position: absolute;
		top: 12px;
		left: 15px;
		font-size: 18px;
	}
}

.badge-status {
	padding: 4px 7px;
	border-radius: 7px;

	&.success {
		background-color: var(--green-success);
		color: var(--dark-green);
	}

	&.danger {
		background-color: var(--red-danger);
		color: var(--red-title);
	}
}

.btn-close:focus {
	outline: none;
	box-shadow: none;
}

.wrapper-table-search {
	background-color: var(--white);
	padding: 20px;
	border-radius: 20px;

	table {
		thead {
			tr {
				th {
					font-style: normal;
					font-weight: 500;
					font-size: 12px;
					text-transform: uppercase;
					color: var(--light-gray);
				}
			}
		}

		tbody {
			tr {
				border-top: 1px solid var(--gray-extra-light);

				td,
				a {
					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 16px;
					color: var(--gray-title);
				}

				&:last-child {
					border-bottom: 1px solid var(--gray-extra-light) !important;
				}
			}
		}
	}
}

.btn-disabled {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
	background-color: var(--disabled-label) !important;
}

.md-drppicker {
	position: absolute;
	min-width: 260px !important;

	.calendar.right {
		display: none !important;
	}

	.available {
		&.active {
			background: var(--royal-blue) !important;
		}
	}

	.buttons_input {
		button {
			padding: 5px 10px !important;
			margin-top: 10px !important;
			border-radius: 5px;
			font-weight: 700;

			&:last-of-type {
				margin-left: 10px;
				background: var(--royal-blue);
			}
		}
	}
}

.modal-summary-calendar {
	.modal-content {
		padding: 20px;
		border-radius: 40px;
		margin: 0 auto;
		max-width: 300px;
	}
}

.select2:focus-visible,
.select2-selection:focus-visible {
	outline: none !important;
}

.table-striped-custom > tbody > tr:nth-of-type(even) {
	background-color: var(--gray-very-light);
}

.fc-col-header-cell-cushion {
	font-variant-caps: small-caps;
}

.select2-results__option--disabled {
	background-color: var(--gray-border);
}

.status-reports {
	.select2-selection {
		width: 140px;
		border-radius: 20px !important;
	}
}

.clockwork {
	&::-webkit-scrollbar {
		width: 20px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px var(--gray-text);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--black-light);
		border-radius: 10px;
	}
}

.custom-scroll {
	max-height: calc(100vh - 500px);
}

.calendar-icon-clock {
	position: absolute;
	cursor: pointer;
	top: 6px;
	left: 4px;
}

.timesheet-detail-clock {
	background-color: var(--gray-border);
	border-radius: 5px;
	padding: 5px;
	height: 100%;
	max-height: 70px;
	overflow: scroll;
	width: 120px;
	display: block;
	position: absolute;
	left: 15px;
	top: 16px;
	transition: all 0.3s ease;
	text-align: center;

	h3 {
		font-size: 12px;
	}
}

.fc-detailsClockWorksheet-button {
	background-color: transparent !important;

	i {
		font-size: 16px;
	}
}

.modal-onboarding-worksheet {
	.modal-content {
		padding: 20px;
		border-radius: 40px;
		margin: 0 auto;
	}
}

.badge {
	font-size: 12px;
}
